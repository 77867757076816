<script setup lang="ts">
import type { FormError } from '#ui/types'
import { format } from 'date-fns'

const props = defineProps<{
  initState?: any
  isemit?: boolean
}>()

const emit = defineEmits(['search'])

const { t } = useI18n()
const localePath = useLocalePath()

const sitedata: any = useSiteData()

const state = ref<any>(props?.initState ?? {})

const options = computed(() => sitedata.value?.book_ticket_place ?? [])

// const options = [
//   { id: 'ID', name: 'Name' },
// ]

function validate(state: any): FormError[] {
  const errors: any = []

  if (!state.value?.dep)
    errors.push({ path: 'dep', message: t('required') })

  if (!state.value?.des)
    errors.push({ path: 'des', message: t('required') })

  if (!state.value?.date)
    errors.push({ path: 'date', message: t('required') })

  return errors
}

async function onSearch() {
  if (!_isEmpty(state.value) && state.value?.dep && state.value?.des && state.value?.date) {
    if (props?.isemit)
      emit('search', state.value)

    else
      await navigateTo({ path: localePath('/book-ticket'), query: state.value })
  }
}
</script>

<template>
  <div class="bg-white mx-auto max-w-screen-lg p-8 pb-16 border rounded-xl">
    <div class="text-center mb-10">
      <div class="mb-6 text-3xl font-bold">
        {{ t('find_trip') }}
      </div>
    </div>

    <UForm
      :validate="validate"
      :state="state"
      class="lg:flex items-end justify-center gap-6"
    >
      <UFormGroup v-slot="{ error }" :label="t('departure_label')" size="xl" :error="!state?.dep && !_isEmpty(state)" class="mb-8 lg:mb-0">
        <USelectMenu
          v-model="state.dep"
          :options="options"
          :placeholder="t('departure_placeholder')"
          searchable
          :searchable-placeholder="t('departure_searchable_placeholder')"
          clear-search-on-close
          value-attribute="id"
          option-attribute="name"
          :search-attributes="['name']"
          :trailing-icon="error ? 'i-heroicons-exclamation-triangle-20-solid' : undefined"
          size="xl"
          :ui="{
            size: {
              xl: 'text-xl',
            },
            default: {
              size: 'xl',
            },
          }"
          :ui-menu="{
            input: 'text-xl',
            option: {
              size: 'text-xl',
            },
          }"
          class="w-full lg:w-48"
        >
          <template #label>
            <span v-if="state?.dep?.name" class="truncate">{{ state?.dep?.name }}</span>
          </template>
          <template #option="{ option: item }">
            <span class="line-clamp-3">{{ item.name }}</span>
          </template>
        </USelectMenu>
      </UFormGroup>
      <UFormGroup v-slot="{ error }" :label="t('destination_label')" size="xl" :error="!state?.des && !_isEmpty(state)" class="mb-8 lg:mb-0">
        <USelectMenu
          v-model="state.des"
          :options="options"
          :placeholder="t('destination_placeholder')"
          searchable
          :searchable-placeholder="t('destination_searchable_placeholder')"
          clear-search-on-close
          value-attribute="id"
          option-attribute="name"
          :search-attributes="['name']"
          :trailing-icon="error ? 'i-heroicons-exclamation-triangle-20-solid' : undefined"
          size="xl"
          :ui="{
            size: {
              xl: 'text-xl',
            },
            default: {
              size: 'xl',
            },
          }"
          :ui-menu="{
            input: 'text-xl',
            option: {
              size: 'text-xl',
            },
          }"
          class="w-full lg:w-48"
        >
          <template #label>
            <span v-if="state?.des?.name" class="truncate">{{ state?.des?.name }}</span>
          </template>
          <template #option="{ option: item }">
            <span class="line-clamp-3">{{ item.name }}</span>
          </template>
        </USelectMenu>
      </UFormGroup>
      <UFormGroup v-slot="{ error }" :label="t('departure_date')" size="xl" :error="!state?.date && !_isEmpty(state)" class="mb-8 lg:mb-0">
        <UInput
          v-model="state.date"
          type="date"
          :placeholder="t('departure_date_placeholder')"
          :trailing-icon="error ? 'i-heroicons-exclamation-triangle-20-solid' : undefined"
          :min="format(new Date(), 'yyyy-MM-dd')"
          color="white"
          variant="outline"
        />
      </UFormGroup>

      <UButton
        size="xl"
        color="black"
        variant="solid"
        :label="t('search')"
        :ui="{
          size: {
            xl: 'text-xl',
          },
          default: {
            size: 'xl',
          },
          padding: {
            xl: 'px-8',
          },
        }"
        class="mb-8 lg:mb-0 w-full lg:w-auto block"
        @click="onSearch"
      />
    </UForm>
  </div>
</template>
